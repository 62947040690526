import styled from "styled-components";
import { BtnPolygon, ImgIcon, RowSpaceBetween, TextOrbitron } from "../../AppElement";

export const ContainerPopUp = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(349.74deg, #24282F -2.06%, #16181D 102.08%);
    border: 1px solid linear-gradient(349.74deg, #24282F -2.06%, #16181D 102.08%);
    color: #fff;
    padding: 40px 15px 15px 15px;
    height: auto;
    min-width: 370px;
    z-index: 10;

    @media (max-width: 600px) {
        min-width: 300px;
    }
`

export const BtnClosePopUpMsg = styled(BtnPolygon)`
    position: absolute;
    top: -14px;
    right: -14px;
    cursor: pointer;
    width: 42px;
    height: 42px;
    background: #c40a0a;
    color: white;
`
export const ImgIconCloseMsg = styled(ImgIcon)`
    width: 15px;
    height: 15px;
`
export const BtnDialogNo = styled(BtnPolygon)`
    background: #1f7275;
    cursor: pointer;
    width: 50%;
    height: 42px;
`
export const BtnDialogYes = styled(BtnDialogNo)`
    background: #31BCC0;
`
export const TextDialogNo = styled(TextOrbitron)`
    text-transform: none;
    font-weight: 700;

    @media (max-width: 600px) {
        font-size: 10px;
        line-height: 15px;
    }

`

export const TextDialogYes = styled(TextDialogNo)`
`
export const RowSpaceBetweenOptions = styled(RowSpaceBetween)`
    margin-top: 5%;
`

export const ContainerChildren = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`
