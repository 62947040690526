import {AccountPageWrapper, ContentWrapper, LeftMenu, PanelAccount} from './accountPageElement.jsx';
import React, { useEffect } from 'react';
import Account from './Account.jsx';
import AccountHeader from './Account-header.jsx';

function AccountPage(props) {

  useEffect(() => {
    props.fetchHealth();
  }, [props]);

  return (
    <AccountPageWrapper>
      <ContentWrapper>
        <LeftMenu>
          <AccountHeader />
        </LeftMenu>
        <PanelAccount>
          <Account />
        </PanelAccount>
      </ContentWrapper>
    </AccountPageWrapper>
  );
}

export default AccountPage;