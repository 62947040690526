
import React from "react";
import { ContainerFooter, FooterSection, FooterSectionContentFollow, FooterLogo, FooterSectionContent, FooterSectionTitle, FooterSectionSubtitle, FooterSocialMedia, SocialMediaContainer, SocialMediaImage, SocialMediaIcon, CurrentYear } from "./FooterElement";
function  Footer() {

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <ContainerFooter>
        <FooterSection>
          <FooterLogo
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ec714728f4bee27bcf38da3c9586a56761a102285cab283f42196a6315aba94?apiKey=08ad1d4e611c46218f052d1b8df15a78&"
          />
          <FooterSectionContent>

            <FooterSectionTitle>
              Company
            </FooterSectionTitle>
            <FooterSectionSubtitle>
              About
            </FooterSectionSubtitle>
            <FooterSectionSubtitle>
              Careers
            </FooterSectionSubtitle>
            <FooterSectionSubtitle>
              Contact
            </FooterSectionSubtitle>

          </FooterSectionContent>
          <FooterSectionContent>

            <FooterSectionTitle>
              Resources
            </FooterSectionTitle>
            <FooterSectionSubtitle>
              Support
            </FooterSectionSubtitle>
            <FooterSectionSubtitle>
              Docs
            </FooterSectionSubtitle>
            <FooterSectionSubtitle>
              FAQ
            </FooterSectionSubtitle>

          </FooterSectionContent>
          <FooterSectionContent>

            <FooterSectionTitle>
              Legal
            </FooterSectionTitle>
            <FooterSectionSubtitle>
              Privacy Policy
            </FooterSectionSubtitle>
            <FooterSectionSubtitle>
              Terms of Service
            </FooterSectionSubtitle>

          </FooterSectionContent>
          <FooterSectionContentFollow>
            <FooterSocialMedia>

              <FooterSectionTitle>
                Follow
              </FooterSectionTitle>
              <SocialMediaContainer>
                <SocialMediaImage>
                  <SocialMediaIcon

                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/61044a0b8f677c84b8bca16f622abc6dbb386b1c76c253ea02e43a9e34d6c6c5?apiKey=08ad1d4e611c46218f052d1b8df15a78&"

                  />
                </SocialMediaImage>
                <SocialMediaImage>
                  <SocialMediaIcon

                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1e219f88cc3fd2cbd5c74ad5b28f2f36ca45546d114fcd0ab257eea6ec8783d?apiKey=08ad1d4e611c46218f052d1b8df15a78&"

                  />
                </SocialMediaImage>
                <SocialMediaImage>
                  <SocialMediaIcon

                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d59c0fc113f879ea87c05250a4444893700252ee3332a79ee1a6414247bb35d1?apiKey=08ad1d4e611c46218f052d1b8df15a78&"

                  />
                </SocialMediaImage>
              </SocialMediaContainer>
              

            </FooterSocialMedia>
            <CurrentYear>
                © {currentYear} Monitor Scanner
              </CurrentYear>
          </FooterSectionContentFollow>
        </FooterSection>
      </ContainerFooter>

  );
}

export default Footer;
