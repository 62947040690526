import styled from "styled-components";

export const ContainerGeneral = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 140px);
`

export const ContainerCodePen = styled.div`
    height: calc(100vh - 140px);
    width: 100%;
`

export const ContainerHarmonics = styled.div`
    height: calc(100vh - 140px);
    width: auto;
    background-color: #282C34;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
`

export const ContainerTableHarmonic = styled.table`
    border-collapse: collapse;
    display: block;
    overflow-y: scroll;
    width: 100%;
    height: calc(100vh - 365px);
`

export const ContainerTableHarmonicHead = styled.thead`

`

export const ContainerRowHarmonicHead = styled.tr`

`

export const ContainerColumnHarmonicHead = styled.th`

`

export const ContainerCellHarmonicHead = styled.div`
    padding: 8px;
    text-align: left;
`

export const ContainerCellTextHarmonicHead = styled.span`
    color: white;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
`

export const ContainerTableHarmonicBody = styled.tbody`

`

export const ContainerRowHarmonicBody = styled.tr`
    border-bottom: 1px solid #3A3A3A;
    &:last-child {
        border-bottom: none;
    }
    cursor: pointer;

`

export const ContainerColumnHarmonicBody = styled.td`

`

export const ContainerCellHarmonicBody = styled.div`
    padding: 8px;
    text-align: left;
`

export const ContainerCellTextHarmonicBody = styled.span`
    color: white;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    gap: 4px;
    align-items: center;
`

export const CloseButton = styled.button`
    color: 'red';
    margin-top: 20px;
    background-color: #31BCC0;
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    border: none;
    color: white;
    cursor: pointer;
`

export const SubActionsContainer = styled.div`
    justify-content: right;
    display: flex;
    width: 100%;
`

export const SubModalContainer = styled.div`
    position: 'relative';
    overflow-y: scroll;
    height: 293px;
    padding: 20px;

`

export const ContainerFilters = styled.div`
    width: auto;
    padding: 12px 16px 12px 16px;
    gap: 22px;
    display: flex;
    flex-direction: column;
`

export const SearchSymbolInput = styled.input`
    background: transparent;
    border: none;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    height: 22px;
    width: 100%;
    color: white;
`

export const ContainerSearchSymbolInput = styled.div`
    padding: 5px 12px 5px 12px;
    border-radius: 20px;
    background-color: #7B7B7B;
    display: flex;
    gap: 4px;
    background: linear-gradient(349.74deg, #24282F -2.06%, #16181D 102.08%);
    align-items: center;
`

export const ImgSearchSymbol = styled.img`
    width: 14px;
    height: 14px;
`

export const ContainerFiltersBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 60%;
`

export const FilterButton = styled.button`
    height: 31px;
    padding: 6px 16px 6px 16px;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #FFFFFF;
    background: transparent;

    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
    color: #FFFFFF;
    text-align: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    position: relative;

`

export const FilterButton2 = styled.div`
    height: 31px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #FFFFFF;
    background: transparent;

    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
    color: #FFFFFF;
    text-align: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    position: relative;

`

export const ModalOptions = styled.div`
    position: absolute;
    width: 500px;
    padding: 16px;
    gap: 6px;
    border-radius: 10px;
    background: #24282F;
    z-index: 50;
    left: -540px;
    top: 30px;
    cursor: default;
    color: white;


`

export const TitleOptions = styled.span`
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;

    width: auto;
    height: 30px;
    padding: 6px 16px 6px 16px;
    gap: 10px;

    border-bottom: 1px solid #5AC9CD;
    display: flex;
    justify-content: center;
    align-items: center;


`

export const ContainerGridOptions = styled.div`
    display: grid;
    /* grid-template-rows: 100px 100px 100px; */
    grid-template-columns: 150px  150px  150px;
    grid-gap: 5px;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 20px;
`

export const GridOption = styled.div`
    display: flex;
    align-items: center;
`

export const CheckBox = styled.input`

`

export const TitleGridOption = styled.span`
    font-family: 'Inter';
    font-size: 13.21px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

`

export const Circle = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 50%;
`

export const ArrowUpORDown = styled.img`
    margin-right: 10px;
`

