import React from 'react'
import { LoadingOverlay } from './LoaderElement'
import { CircularProgress } from '@mui/material'

export function Loader({loading}) {


  return (
    <>
    { loading ?
      <LoadingOverlay>
          <CircularProgress thickness={5} sx={{color: '#31BCC0'}}/>
      </LoadingOverlay>
      :
      null
    }
    </>
  )
}
