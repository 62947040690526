import styled from "styled-components";


export const AccountPageWrapper = styled.div`
  width: auto;
  height: calc(100vh - 140px);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 30px;
`;

export const LeftMenu = styled.div`

`;

export const PanelAccount = styled.div`
  height: 100%;
`;
