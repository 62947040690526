import React, { useState, useEffect, useCallback, useRef } from "react";


import { HTTP } from '../../http/http-common';
import { ArrowUpORDown, CheckBox, Circle, CloseButton, ContainerCellHarmonicBody, ContainerCellHarmonicHead, ContainerCellTextHarmonicBody, ContainerCellTextHarmonicHead, ContainerCodePen, ContainerColumnHarmonicBody, ContainerColumnHarmonicHead, ContainerFilters, ContainerFiltersBar, ContainerGeneral, ContainerGridOptions, ContainerHarmonics, ContainerRowHarmonicBody, ContainerRowHarmonicHead, ContainerSearchSymbolInput, ContainerTableHarmonic, ContainerTableHarmonicBody, ContainerTableHarmonicHead, FilterButton, FilterButton2, GridOption, ImgSearchSymbol, ModalOptions, SearchSymbolInput, SubActionsContainer, SubModalContainer, TitleGridOption, TitleOptions } from "./IdeasPageElement";
import { checkIfIncludedSymbol, convertTimestampToDate, futuresCommodities, futuresIndices, majorCryptoPairs, majorPairs, minorPairs, pairs, useOutsideAlerter } from "../../utils/utils";

import InputSearchIcon from '../../../assets/images/InputSearchIcon.png'
import TradingViewWidget from "../LiveChart/TradingViewWidget";
import { useLocation } from "react-router-dom";

import ArrowDown from '../../../assets/images/ArrowDown.png'
import ArrowUp from '../../../assets/images/ArrowUp.png'
import PopUp from "../../hooks/PopUp";
import { TextPoppins } from "../../../AppElement";
import { Loader } from "../../hooks/Loader";

function setRow(data, props) {
  console.log("SET ROW", data)
  props.setData(data)
  props.setCurrentSymbol(data.symbol)
  props.setInterval(data.displaytimeframe)
  props.setTakeProfits({"profit1": data.profit1, "profit2": data.profit2, "time" : data.atime})
  props.setStopLoss(data.stoploss)
  props.setEntryPrice(data.entry)
}

function HarmonicTable(props) {

  // const [checkParams, setCheckParams] = useState(true);
  // useEffect(() => {
  //   if (props.rows && props.rows.length > 0){
  //     if (checkParams) {
  //       console.log(props.queryParams)
  //       if (props.queryParams.get('symbol') && props.queryParams.get('pattern') && props.queryParams.get('timeFrame')){
  //         let foundNotification = false;
  //         props.rows.forEach((row) => {

  //           if (props.queryParams.get('symbol') && props.queryParams.get('pattern') && props.queryParams.get('timeFrame')){
  //             if (row.displaysymbol === props.queryParams.get('symbol') && row.patternname === props.queryParams.get('pattern') && row.displaytimeframe === props.queryParams.get('timeFrame')){
  //               setRow(row, props);
  //               foundNotification = true;
  //               return;
  //             }
  //           }
  //         })

  //         if (!foundNotification){
  //           props.setShowPopUpNotiRecent(true)
  //         }
  //       }
  //       setCheckParams(false);
  //     }
  //   }
  // }, [props, checkParams])

  return (
    <ContainerTableHarmonic>
      <ContainerTableHarmonicHead>
        <ContainerRowHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Symbol
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Pattern
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                TimeFrame
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Status
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead style={{width: '130px'}}>
              <ContainerCellTextHarmonicHead>
                Date
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
        </ContainerRowHarmonicHead>
      </ContainerTableHarmonicHead>
      <ContainerTableHarmonicBody>
        {
          props.rows.map((row) => {

            return (
            <>
            { checkIfIncludedSymbol(row.displaysymbol) ?
              <ContainerRowHarmonicBody onClick={()=>setRow(row, props)}>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody style={{display: 'flex'}}>
                    {parseFloat(row.profit1) < parseFloat(row.entry) && parseFloat(row.stoploss) > parseFloat(row.entry) ?
                      <ArrowUpORDown src={ArrowDown} />
                      :
                      <ArrowUpORDown src={ArrowUp} />
                    }
                    <ContainerCellTextHarmonicBody>
                      {row.displaysymbol}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {row.patternname}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {row.displaytimeframe}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {row.status === 'failed'?
                        <Circle style={{background: '#DF0707'}} />
                      :
                        <>
                        {row.status === 'successful'?
                          <Circle style={{background: '#1DA534'}}/>
                        :
                          <>
                          {row.status === 'complete'?
                            <Circle style={{background: '#0099ff'}}/>
                          :
                            <>
                            {row.status === 'incomplete'?
                              <Circle style={{background: '#ffff00'}}/>
                            :
                              null
                            }
                            </>
                          }
                          </>
                        }
                        </>
                      }
                      {row.status}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                    {convertTimestampToDate(row.sorttime)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
              </ContainerRowHarmonicBody>
              :
              null
              }
            </>
            )
          })
        }
      </ContainerTableHarmonicBody>
    </ContainerTableHarmonic>
  );
}

function ContainerOptions(props){

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name)
    console.log(props.filters)
    if (props.filters.includes(name)){
      props.setFilters(prevState => prevState.filter(item => item !== name));
    }else{
      if (checked){
        props.setFilters(prevState => [...prevState, name]);
      }
    }
  }
  return (
    <>
      <TitleOptions>
        {props.title}
      </TitleOptions>
      <ContainerGridOptions>
        {
        props.items.map((item) => (
          <GridOption>
            <CheckBox type="checkbox" onChange={handleCheckboxChange} name={item} checked={props.filters.includes(item)}/>
            <TitleGridOption>
              {item}
            </TitleGridOption>
          </GridOption>
        ))
      }
      </ContainerGridOptions>
    </>
  );
}


function IdeasPage(props) {
  const [filtersPatternStatus, setFiltersPatternStatus] = useState([]);
  const [filtersTimeFrame, setFiltersTimeFrame] = useState([]);
  const [filtersPattern, setFiltersPattern] = useState([]);
  const [filtersSymbol, setFiltersSymbol] = useState([]);

  const [data, setData] = useState();
  const [currentSymbol, setCurrentSymbol] = useState();
  const [interval, setInterval] = useState("");
  const [takeProfits, setTakeProfits] = useState([]);
  const [entryPrice, setEntryPrice] = useState(0);
  const [stopLoss, setStopLoss] = useState(0);

  const [patternStatus, setPatternStatus] = useState([]);
  const [timeFrame, setTimeFrame] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [symbol, setSymbol] = useState([]);

  const [openSettings, setOpenSettings] = useState(false);
  const [openPairs, setOpenPairs] = useState(false);

  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUpNotiRecent, setShowPopUpNotiRecent] = useState(false);

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const [loader, setLoader] = useState(false);

  const useRefopenSettings = useRef(null);
  useOutsideAlerter(useRefopenSettings, setOpenSettings);

  const useRefopenPairs = useRef(null);
  useOutsideAlerter(useRefopenPairs, setOpenPairs);

  const fetchRoutesTimes = useCallback(() => {
    HTTP.request('scan/pattern/allpoints')
      .then((res) => {
        console.log("RESRES", res);
        if (res.data.result) {
          setRows(res.data.rows);
          setFilteredRows(res.data.rows);

          setPatternStatus([...new Set(res.data.rows.map(item => item.status))]);
          setTimeFrame([...new Set(res.data.rows.map(item => item.displaytimeframe))]);
          setPattern([...new Set(res.data.rows.map(item => item.patternname))]);
          setSymbol(pairs);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  }, []);

  const fetchGetPairs = useCallback(() => {
    HTTP.request('get/pairs/telegram', {withCredentials: true})
      .then((res) => {
        if (res.data.result) {
          setFiltersSymbol(res.data.response)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  }, []);

  const updatePairs = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = {
      "pairs": filtersSymbol
    };

    var config = {
      headers: headers,
      data: raw,
      method: "POST",
      withCredentials: true
    };

    HTTP.request('update/pairs/telegram', config)
    .then((res) => {
        console.log(res);
    })
    .catch((err) => {
        console.log(err);
    })
    .finally(() => {
    });

    setOpenPairs(false)
  };

  const searchSymbolInput = (event) => {
    console.log(event)
    var value = event.target.value;
    if (value.length > 0) {
      setFilteredRows(rows.filter(item => 
        (value.length > 0? item.displaysymbol.includes(value.toUpperCase()) : true)
      ));
    }else{
      setFilteredRows(rows.filter(item => 
        (filtersPatternStatus.length > 0 ? filtersPatternStatus.includes(item.status) : true) 
        & (filtersTimeFrame.length > 0 ? filtersTimeFrame.includes(item.displaytimeframe)  : true)
        & (filtersPattern.length > 0 ? filtersPattern.includes(item.patternname)  : true) 
        & (filtersSymbol.length > 0 ? filtersSymbol.includes(item.displaysymbol)  : true)
      ));
    }
    
  }

  useEffect(() => {
    props.fetchHealth();
  }, [props]);

  useEffect(() => {
    setLoader(true);
    fetchGetPairs();
    fetchRoutesTimes();

    const timer = setTimeout(() => {
      setLoader(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [fetchRoutesTimes, fetchGetPairs]);

  useEffect(() => {
    if (filtersPatternStatus.length > 0 || filtersTimeFrame.length > 0 || filtersPattern.length > 0 || filtersSymbol.length > 0){
      
      var filterResult = rows;
      var newFilterResult = []
      newFilterResult = newFilterResult.concat(filterResult.filter(item => 
        (document.getElementById("idSearchSymbol").value.length > 0? item.displaysymbol.includes(document.getElementById("idSearchSymbol").value) : true)
        & (filtersPatternStatus.length > 0 ? filtersPatternStatus.includes(item.status) : true) 
        & (filtersTimeFrame.length > 0 ? filtersTimeFrame.includes(item.displaytimeframe)  : true)
        & (filtersPattern.length > 0 ? filtersPattern.includes(item.patternname)  : true) 
        & (filtersSymbol.length > 0 ? filtersSymbol.includes(item.displaysymbol)  : true)
      ));

      console.log(newFilterResult)
      setFilteredRows(newFilterResult);
    }else{
      setFilteredRows(rows.filter(item => 
        (document.getElementById("idSearchSymbol").value.length > 0? item.displaysymbol.includes(document.getElementById("idSearchSymbol").value) : true)
      ));
    }
  }, [rows, filtersPatternStatus, filtersTimeFrame, filtersPattern, filtersSymbol]);

  const clearEverything = () =>{
    setFiltersSymbol([])
    setFiltersPatternStatus([]);
    setFiltersTimeFrame([]);
    setFiltersPattern([]);
    document.getElementById("idSearchSymbol").value = "";
    setShowPopUp(false);
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  return (
    <ContainerGeneral>
        <Loader loading={loader}/>
        <ContainerCodePen>
          <TradingViewWidget data={data} symbol={currentSymbol} interval={interval} takeProfits={takeProfits} stopLoss={stopLoss} entryPrice={entryPrice}/>
        </ContainerCodePen>
        <ContainerHarmonics>
          <ContainerFilters>
            <ContainerSearchSymbolInput>
              <ImgSearchSymbol src={InputSearchIcon}/>
              <SearchSymbolInput onChange={searchSymbolInput} placeholder="Search symbol" id="idSearchSymbol" />
            </ContainerSearchSymbolInput>
            <ContainerFiltersBar>
              {openSettings ?
                <FilterButton2>
                  Settings
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenSettings(true)}>
                  Settings
                </FilterButton>
              }
              {openSettings ? 
                <ModalOptions ref={useRefopenSettings}>
                  <SubModalContainer>
                    <ContainerOptions title='Patter Status' items={patternStatus} setFilters={setFiltersPatternStatus} filters={filtersPatternStatus} />
                    <ContainerOptions title='Time Frame' items={timeFrame} setFilters={setFiltersTimeFrame} filters={filtersTimeFrame}/>
                    <ContainerOptions title='Pattern' items={pattern} setFilters={setFiltersPattern} filters={filtersPattern}/>
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => setOpenSettings(false)}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              {openPairs ?
                <FilterButton2>
                  Pairs
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenPairs(true)}>
                  Pairs
                </FilterButton>
              }
              {openPairs ?
                <ModalOptions ref={useRefopenPairs}>
                  <SubModalContainer>
                    <ContainerOptions title='Major Pairs' items={symbol.filter(element => majorPairs.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Minor Pairs' items={symbol.filter(element => minorPairs.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Futures Indices' items={symbol.filter(element => futuresIndices.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Futures Commodities' items={symbol.filter(element => futuresCommodities.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Cryptocurrency Pairs' items={symbol.filter(element => {
                        if (element.includes("/")) {
                          if (majorCryptoPairs.includes(element.split('/')[0])){
                            return element
                          }
                        }
                        return null
                      })} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => updatePairs()}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              {/* <FilterButton>
                Fullscreen
              </FilterButton> */}
              <FilterButton onClick={() => setShowPopUp(true)}>
                Clear
              </FilterButton>
              {/* <FilterButton>
                Screenshot
              </FilterButton> */}
            </ContainerFiltersBar>
          </ContainerFilters>
          <HarmonicTable rows={filteredRows} setData={setData} setCurrentSymbol={setCurrentSymbol} setInterval={setInterval} setTakeProfits={setTakeProfits} setStopLoss={setStopLoss} setEntryPrice={setEntryPrice} queryParams={queryParams} setShowPopUpNotiRecent={setShowPopUpNotiRecent}/>
        </ContainerHarmonics>
        <PopUp show={showPopUp} onClose={() => setShowPopUp(false)}  onlyConfirm={false} onConfirm={()=>clearEverything()}>
          <TextPoppins style={{letterSpacing: '0'}}>
          Are you sure to clear everything?
          </TextPoppins>
        </PopUp>
        <PopUp show={showPopUpNotiRecent} onClose={() => setShowPopUpNotiRecent(false)}  onlyConfirm={true}>
          <TextPoppins style={{letterSpacing: '0'}}>
          This notification is very recent, please wait a few minutes!
          </TextPoppins>
        </PopUp>
    </ContainerGeneral>
  );
}

export default IdeasPage;
