import React, { useEffect } from 'react';
import TradingViewWidget from './TradingViewWidget.jsx';
import Watchlist from './Watchlist.jsx';

export function LiveChart(props) {
  useEffect(() => {
    props.fetchHealth();
  }, [props]);

  return (
    <div className="App">
      <div className="main-div" style={{display: "flex", height: "calc(100vh - 140px)"}}>
        <div className="tradingview-widget" style={{width: "100%", height: "100%", borderRadius: "20px 0 0 20px"}}>
          <TradingViewWidget />
        </div>
        <div className="panel-menu" style={{width: "30%", height: "100%", background: "linear-gradient(350deg, #2c3139 -2.06%, #16181d 102.08%)", borderRadius: "0 20px 20px 0"}}>
          <Watchlist/>
        </div> 

      </div>
    </div>
  );
}