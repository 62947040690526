import React, { useState } from "react";
import { HTTP } from "../../../../http/http-common";
import { BtnSignIn, ContainerSign, ErrorItems, FieldErrorHeaderStatus, FieldErrorStatus, FormControl, FormErrorHeader, FormLabel, FormTitle, InputPassword, InputUsername, RowItems, SignInForm, TextButtons, TextInter } from "./SignInElement";

import { AiOutlineEyeInvisible, AiOutlineEye, AiFillCloseCircle } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import { BiLockAlt } from 'react-icons/bi';
import { GoAlert } from 'react-icons/go';

export function SignIn(props){

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [errorHeader, setErrorHeader] = useState('');

    const loginUser = (credentials) => {
  
        var formdata = new FormData();
        formdata.append("email", credentials.username);
        formdata.append("password", credentials.password);
    
        var config = {
            data: formdata,
            method: "POST",
            withCredentials: true
        };
    
        HTTP.request('signin', config)
        .then((res) => {
            console.log(res);
            if (res.data.result) {
                props.fetchHealth();
            }else{
                setErrorHeader(res.data.response);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }


    const handleSubmit = async e => {
        e.preventDefault();

        let errors = {};
        let msgErrorHeader = '';
        if (!username) {
        errors.usernameEnter = 'Please enter your email';
        } else {
        delete errors.usernameEnter;
        }
        //  else if (username && !emailValidation(username)) {
        //   errors.usernameEnter = 'Please enter a valid email';
        // }
        if (!password) {
        errors.passwordEnter = 'Please enter your password';
        } else {
        delete errors.passwordEnter;
        }

        if (username && password && Object.keys(errors).length === 0) {
        try {

            loginUser({
                username,
                password,
            })
        }
        catch (error) {
            console.log(error);
            msgErrorHeader = 'Server error!';
        }
        } else {
        msgErrorHeader = 'Please check the errors marked in red and click the Sign In button again after fixing them.';
        }
        setErrorHeader(msgErrorHeader);
        setError(errors);
    }

    const onChangeName = (e) => {
        setUsername(e.target.value);
        delete error.usernameEnter;
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        delete error.passwordEnter;
    }

    return (
        <>
            <ContainerSign>
            <SignInForm onSubmit={handleSubmit}>
                <FormTitle>Sign In</FormTitle>
                {errorHeader &&
                <FormErrorHeader>
                    <ErrorItems style={{ fontWeight: 'bold', fontSize: 12, gap: 5 }}>
                    <div style={{ width: 4, height: 30, backgroundColor: '#FF0000' }} />
                    <AiFillCloseCircle color='FF0000' size={28} />
                    <TextInter style={{ gap: 4, color: 'white' }}>ERROR</TextInter>
                    <FieldErrorHeaderStatus>{errorHeader}</FieldErrorHeaderStatus>
                    </ErrorItems>
                </FormErrorHeader>}
                <FormLabel>Email</FormLabel>
                <FormControl onInvalid={error.usernameEnter}>
                    <FaUserAlt color='31BCC0' size={24} />
                    <InputUsername placeholder={"Insert your email"} onChange={onChangeName}></InputUsername>
                </FormControl>
                {error.usernameEnter && <ErrorItems><GoAlert color='31BCC0' size={18} /><FieldErrorStatus>{error.usernameEnter}</FieldErrorStatus></ErrorItems>}
                <FormLabel>Password</FormLabel>
                <FormControl onInvalid={error.passwordEnter}>
                <BiLockAlt color='31BCC0' size={24} />
                <InputPassword onChange={onChangePassword} name='password' type={showPassword ? 'text' : 'password'} placeholder={" Insert your password"} />
                {showPassword ? <AiOutlineEye color='31BCC0' size={24} onClick={() => setShowPassword(false)} /> : <AiOutlineEyeInvisible color='31BCC0' size={24} onClick={() => setShowPassword(true)} />}
                </FormControl>
                {error.passwordEnter && <ErrorItems><GoAlert color='31BCC0' size={18} /><FieldErrorStatus>{error.passwordEnter}</FieldErrorStatus></ErrorItems>}
                <RowItems style={{ marginTop: 15 }}>
                </RowItems>
                <BtnSignIn type="submit">
                <TextButtons>Sign in</TextButtons>
                </BtnSignIn>
                {/* <FormSignInLink><a href="/signup">Create account</a></FormSignInLink> */}
            </SignInForm>
            </ContainerSign>
        </>
    )
}