import styled from 'styled-components'

export const IconSMA = styled.img`
    width: 450px;

`

export const ContainerSign = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1230px) {
        margin-top: 40px;
        width: 90%;
        margin-bottom: 5%;
    }
`

export const SignInForm = styled.form`
    width: 100%;
    padding: 50px;
    background: linear-gradient(349.74deg, #24282F -2.06%, #16181D 102.08%);
    border-radius: 100px;
    max-width: 400px;
`;
 
export const ResponsiveContainer = styled.div`
    /* @media (max-width: 1200px) {
        display: none;
    } */
`
export const ResponsiveAlert = styled.div`
    @media (min-width: 1201px) {
        display: none;
    }
`

export const ForgotLink = styled.div`
    font-size: 18px;
    color: white;
    a {
        color: #E7E7E7;
        text-decoration: underline;
        font-family: 'Inter';
    }
`;
export const RowItems = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const FormTitle = styled.div`
    font-weight: 600;
    color: white;
    font-size: 30px;
    line-height: 36px;
    font-family: 'Inter';
    margin-bottom: 10px;
`

export const FormErrorHeader = styled.div`
    padding: 10px 20px 4px 20px;
    margin-top: 10px;
    background: linear-gradient(349.74deg, #24282F -2.06%, #16181D 102.08%);
`;

export const ErrorItems = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TextInter = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
    line-height: 35px;
    color: #FFFFFF;
`

export const FieldErrorStatus = styled.label`
    margin-left: 10px;
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    `;

export const FieldErrorHeaderStatus = styled(FieldErrorStatus)`
    margin-left: 5px;
    font-size: 12px;
    font-family: 'Inter';
    `;

export const FormLabel = styled.label`
    color: white;
    margin-top: 15px;
    position: relative;
    font-family: 'Inter';
    font-size: 14px;
    &:after{
        content: '*';
        color: #E7E7E7;
        position: absolute;
        right: -10px;
        top: 0;
    }
    `;

export const FormControl = styled.div`
    width: 100%;
    height: 44px;
    border: 1px solid ${props => (props.onInvalid ? '#FF0000' : '#E7E7E7')};
    background: linear-gradient(349.74deg, #24282F -2.06%, #16181D 102.08%);

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
`;

export const InputUsername = styled.input`
    width: calc(100% - 34px);
    height: 44px;
    color: white;
    background: transparent;
    outline: none;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    &::placeholder {
        color: rgba(255, 255, 255, 0.70);
        font-family: 'Inter';
        font-size: 18px;
        line-height: 24px;
        font-style: normal;
        font-weight: 400;
    }
    &:-internal-autofill-selected {
        background-color: transparent !important;
    }
`

export const InputPassword = styled(InputUsername)`
    width: calc(100% - 60px);
`

export const FormCheck = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    color: white;
    a {
        color: #E7E7E7;
        text-decoration: underline;
    }
`;

export const CheckLabel = styled.label`
   margin-left: 10px;
   color: white;
   font-family: 'Inter';
   font-size: 18px;
   a {
    color: #E7E7E7;
    font-family: 'Inter';
    font-size: 18px;
    text-decoration: underline;
   }
    `;


export const BtnSignIn = styled.button`
    color: white;
    width: 100%;
    background: #31BCC0;
    height: 54px;
    margin-top: 30px;
    border-radius: 100px;
    border: none;
    cursor: pointer;
`

export const TextButtons = styled(TextInter)`
    font-weight: 600;
    letter-spacing: 0.02em;
    font-size: 18px;
    padding: 12px 0px;
`

export const FormSignInLink = styled.div`
    text-align: center;
    color: white;
    margin-top: 10px;
    font-family: 'Inter';
    font-size: 18px;
    a {
        color: #E7E7E7;
        font-family: 'Inter';
        text-decoration: underline;
    }
`;