import React, { useState } from "react";
import { ContainerSubNavbar, ContainerSubNavbarOption, ContainerSubNavbarOptionArrow, ContentSVO, IconSVO, IconSVOText } from "./SubNavbarElement";

import LogoutIcon from '../../assets/images/LogoutIcon.png'
import ArrowSquareLeftIcon from '../../assets/images/ArrowSquareLeftIcon.png'
import ArrowSquareRightIcon from '../../assets/images/ArrowSquareRightIcon.png'
import { HTTP } from "../http/http-common";
import { useLocation, useNavigate } from "react-router-dom";

  export function SubNavbar(){
    const [show, setShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const singoutUser = (credentials) => {

        window.location.href = "/";
  
        var config = {
            method: "POST",
            withCredentials: true
        };
    
        HTTP.request('signout', config)
        .then((res) => {
            console.log(res);
            if (res.data.result) {
              window.location.reload();
            }
        })
        .catch((err) => {
            console.log(err);
        });
      }

    return (
      <ContainerSubNavbar style={{width: show ? '20%' : 'auto', flexShrink: show ? 0 : 1}}>
        <ContainerSubNavbarOptionArrow onClick={() => setShow(!show)}>
            <IconSVO src={show ? ArrowSquareLeftIcon : ArrowSquareRightIcon}/>
        </ContainerSubNavbarOptionArrow>

        <ContainerSubNavbarOption onClick={()=>navigate("/livechart")} style={{background: location.pathname === '/livechart' ? '#31bbc03d' : 'transparent'}}>
            {show ?
                <ContentSVO>
                    Live Chart
                </ContentSVO>
                :
                <IconSVOText>
                    L
                </IconSVOText>
            }
        </ContainerSubNavbarOption>
        <ContainerSubNavbarOption onClick={()=>navigate("/ideas")} style={{background: location.pathname === '/ideas' ? '#31bbc03d' : 'transparent'}}>
            {show ?
                <ContentSVO>
                    Trading Ideas
                </ContentSVO>
                :
                <IconSVOText>
                    T
                </IconSVOText>
            }
        </ContainerSubNavbarOption>
        <ContainerSubNavbarOption onClick={()=>navigate("/notifications")} style={{background: location.pathname === '/notifications' ? '#31bbc03d' : 'transparent'}}>
            {show ?
                <ContentSVO>
                    Notifications
                </ContentSVO>
                :
                <IconSVOText>
                    N
                </IconSVOText>
            }
        </ContainerSubNavbarOption>
        <ContainerSubNavbarOption style={{position: 'absolute', bottom: '0'}} onClick={()=>{singoutUser()}}>
            <IconSVO src={LogoutIcon}/>
            {show ?
                <ContentSVO>
                    Sign Out
                </ContentSVO>
                :
                null
            }
        </ContainerSubNavbarOption>
      </ContainerSubNavbar>
    )
}
