import React from "react";

const TabItem = ({ icon, label, isActive }) => (
  <div className={`tab-item ${isActive ? "active" : ""}`}>
    <img src={icon} alt="" className="tab-icon" />
    <div className="tab-label">{label}</div>
  </div>
);

const tabData = [
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/280afbff76149a14d620d4ec47d76de5b20132224767fc5d66b63e03fbf2735c?apiKey=08ad1d4e611c46218f052d1b8df15a78&", label: "Account" }
];

function AccountHeader() {
  return (
    <><button>
        <nav className="tab-container">
          {tabData.map((tab, index) => (
            <TabItem
              key={tab.label}
              icon={tab.icon}
              label={tab.label}
              isActive={index === 0}
            />
          ))}
        </nav>
      </button>
      <style jsx>{`
        .tab-container {
          display: flex;
          gap: 0;
          font-size: 14px;
          color: var(--Text-Medium, #7b7b7b);
          font-weight: 400;
          white-space: nowrap;
        }

        button{
          background-color: transparent;
          border: none;
          cursor: pointer
        }

        @media (max-width: 991px) {
          .tab-container {
            flex-wrap: wrap;
            white-space: initial;
          }
        }

        .tab-item {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 6px 16px;
          border: 2px solid rgba(111, 114, 119, 1);
          font-family: Open Sans, sans-serif;
        }

        .tab-item.active {
          color: var(--Text-Heading, #31bcc0);
          border-color: rgba(49, 188, 192, 1);
        }

        .tab-item:first-child {
          border-radius: 16px 0 0 16px;
        }

        .tab-item:last-child {
          border-radius: 0 16px 16px 0;
        }

        .tab-icon {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      `}</style>
    </>
  );
}

export default AccountHeader;