import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { HTTP } from "../../http/http-common";

const ProfileImage = ({ src, alt }) => (
  <img className="profile-image" src={src} alt={alt} />
);

const ProfileInfo = ({ name, email, token, plan, country }) => (
  <div className="profile-info">
    <div className="info-row">
      <div className="info-label">Name:</div>
      <div className="info-value">{name}</div>
    </div>
    <div className="info-row">
      <div className="info-label">E-mail:</div>
      <div className="info-value">{email}</div>
    </div>
  </div>
);



function Account() {

  const [profileData, setProfileData] = useState({});

  const fetchUser = useCallback(() => {
    HTTP.request('user', {withCredentials: true})
      .then((res) => {
        console.log(res);
        if (res.data.result){
          setProfileData(res.data.response)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);
  return (
    <>
      <div className="profile-container">
          <section className="profile-section">
            <header className="profile-header">
              <h2 className="profile-title">My profile</h2>
              {/* <button className="edit-profile-button">
                <img
                  className="edit-icon"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e65118a82be265e8318a55c2628be8c753389465ce409e73cfc90c031749cbc3?apiKey=08ad1d4e611c46218f052d1b8df15a78&"
                  alt="Edit profile icon"
                />
                <span>Edit profile</span>
              </button> */}
            </header>
            <div className="profile-content">
              <ProfileImage src={profileData.image_src} alt={profileData.image_alt} />
              <ProfileInfo
                name={profileData.email && profileData.email.includes("@") ? profileData.email.split("@")[0] : profileData.email}
                email={profileData.email}
              />
            </div>
          </section>
          {/* <section className="settings-section">
            <h2 className="settings-title">Settings</h2>
            {settingsToggles.map((toggle, index) => (
              <SettingsToggle
                key={index}
                label={toggle.label}
                iconSrc={toggle.iconSrc}
                alt={toggle.alt}
              />
            ))}
          </section> */}
      </div>

      <style jsx>{`
        .profile-container {
          display: flex;
          flex-direction: row;
          gap: 20px;
          height: 100%;
        }

        @media (max-width: 991px) {
          .profile-container {
            flex-direction: column;
            align-items: stretch;
            gap: 0;
          }
        }

        .profile-column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: auto;
          margin-left: 0;
          border-radius: 20px;
        }

        @media (max-width: 991px) {
          .profile-column {
            width: 100%;
          }
        }

        .profile-section {
          border-radius: 20px;
          background: linear-gradient(360deg, #2c3139 -2.06%, #16181d 102.08%);
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 30px;
          padding-left: 30px;
          padding-right: 30px;
        }

        @media (max-width: 991px) {
          .profile-section {
            max-width: 100%;
            margin-top: 25px;
            padding: 0 20px;
          }
        }

        .profile-header {
          display: flex;
          height: auto;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
        }

        @media (max-width: 991px) {
          .profile-header {
            flex-wrap: wrap;
          }
        }

        .profile-title {
          color: var(--Text-Light, #e7e7e7);
          font: 600 22px Inter, sans-serif;
        }

        @media (max-width: 991px) {
          .profile-title {
            max-width: 100%;
          }
        }

        .edit-profile-button {
          justify-content: center;
          border-radius: 100px;
          border: 1px solid rgba(255, 255, 255, 1);
          display: flex;
          gap: 10px;
          font-size: 14px;
          color: var(--Text-Light, var(--white-100, #fff));
          font-weight: 400;
          padding: 6px 16px;
          background: none;
          cursor: pointer;
        }

        .edit-icon {
          width: 20px;
        }

        .profile-content {
          display: flex;
          gap: 50px;
          font-size: 16px;
          color: var(--Text-Medium, #7b7b7b);
          font-weight: 400;
        }

        @media (max-width: 991px) {
          .profile-content {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }

        .profile-image {
          width: 75px;
          box-shadow: 0 10.714px 42.857px 0 rgba(49, 188, 192, 0.5);
          align-self: start;
        }

        .profile-info {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          flex: 1;
          padding: 0 16px 11px 0;
        }

        .info-row {
          margin-top: 23px;
        }

        .info-label {
          font-family: Inter, sans-serif;
        }

        .info-value {
          color: var(--Text-Light, #e7e7e7);
          margin-top: 16px;
          font: 14px Poppins, sans-serif;
        }

        .settings-column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: auto;
        }

        @media (max-width: 991px) {
          .settings-column {
            width: 100%;
          }
        }

        .settings-section {
          border-radius: 20px;
          background: linear-gradient(350deg, #2c3139 -2.06%, #16181d 102.08%);
          display: flex;
          flex-direction: column;
          font-size: small;
          color: var(--Text-Light, #e7e7e7);
          font-weight: 100;
          padding: 20px;
          max-width: 100%;
          width: auto;
          height: 30vh;
          justify-content: center;
        }

        @media (max-width: 991px) {
          .settings-section {
            padding: 0 20px;
          }
        }

        .settings-title {
          font: 22px Inter, sans-serif;
        }

        .settings-toggle {
          display: flex;
          margin-top: 12px;
          gap: 10px;
          padding: 6px 16px;
        }

        .toggle-label {
          font-family: SF Pro Text, sans-serif;
          flex: 1;
          margin: auto 0;
        }

        .toggle-icon {
          width: 24px;
        }
      `}</style>
    </>
  );
}

export default Account;