import styled from "styled-components";

export const ContainerSubNavbar = styled.div`
    max-width: 247px;
    border-radius: 20px;
    padding: 10px;
    background: linear-gradient(350deg, #24282f -2.06%, #16181d 102.08%);
    position: relative;
    display: flex;
    flex-flow: column;
    gap: 10px;

`

export const ContainerSubNavbarOption = styled.div`
    border-radius: 100px;
    padding: 10px 10px 10px 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
`

export const IconSVO = styled.img`
    width: 28px;
    height: 28px;
`

export const IconSVOText = styled.div`
    width: 28px;
    height: 28px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContentSVO = styled.span`
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 16.94px;
`

export const ContainerSubNavbarOptionArrow = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 45%;
    right: -20px;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    padding: 6px;
    background-color: rgba(0, 0, 0, 0.403);
    justify-content: center;
    align-items: center;
`