import styled from "styled-components";

export const ContainerFooter = styled.div`
    margin-top: 20px;
`

export const FooterSection = styled.div`
    justify-content: space-between;
    align-items: start;
    display: flex;
    gap: 25px;
    font-size: 14px;
    font-weight: 100;
    white-space: nowrap;
`
export const FooterLogo = styled.img`
    width: 110px;
    align-self: start;
    max-width: 100%;
`

export const FooterSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const FooterSectionTitle = styled.div`
    color: #ffffff;

`

export const FooterSectionSubtitle = styled.div`
    color: #999;

`

export const FooterSocialMedia = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;

`
export const SocialMediaImage = styled.div`
    padding-right: 20px;
`

export const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const SocialMediaIcon = styled.img`
`

export const CurrentYear = styled.div`
    color: #999;
    bottom: 0;

`

export const FooterSectionContentFollow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92px;

`
