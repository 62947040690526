import { Header } from './components/hooks/Header';
import { ContainerContent, ContainerMain, ContainerSubNavbar } from './AppElement';
import { SubNavbar } from './components/hooks/SubNavbar';
import Footer from './components/hooks/Footer';
import {
    Routes,
    Route,
    Navigate
  } from "react-router-dom";

import IdeasPage from "./components/Pages/IdeasPage/ideasPage";
import { NotificationsPage } from "./components/Pages/NotificationsPage/notificationsPage";
import AccountPage from "./components/Pages/AccountPage/accountPage";
import { LiveChart } from "./components/Pages/LiveChart/LiveChart";
import { useCallback, useEffect, useState } from 'react';
import { HTTP } from './components/http/http-common';

export function Home(props) {

    const [profileData, setProfileData] = useState({});

    const fetchUser = useCallback(() => {
      HTTP.request('user', {withCredentials: true})
        .then((res) => {
          console.log(res);
          if (res.data.result){
            setProfileData(res.data.response)
          }

          if (props.doReload){

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, [props.doReload]);
    
    useEffect(() => {
      fetchUser();
    }, [fetchUser]);

    useEffect(() => {
        props.fetchHealth();
      }, [props]);

    return (
    <>  
        <Header profileData={profileData}/>
        <ContainerSubNavbar>
            <SubNavbar />
            <ContainerMain>
                <ContainerContent>
                <Routes>
                    <Route path="/livechart" element={<LiveChart fetchHealth={props.fetchHealth}/>}/>
                    <Route path="/ideas" element={<IdeasPage fetchHealth={props.fetchHealth}/>}/>
                    <Route path="/notifications" element={<NotificationsPage fetchHealth={props.fetchHealth} profileData={profileData}/>}/>
                    <Route path="/account" element={<AccountPage fetchHealth={props.fetchHealth}/>}/>
                    <Route path="*" element={<Navigate replace to="/livechart" />}/>
                </Routes>
                </ContainerContent>
            </ContainerMain>
        </ContainerSubNavbar>
        <Footer/>
    </>
    )

}