//Librerias INIT
import React from "react";

//CSS

//Icons

//Elements
import { BtnClosePopUpMsg, BtnDialogNo, BtnDialogYes, ContainerChildren, ContainerPopUp, PopupOverlay, RowSpaceBetweenOptions, TextDialogNo, TextDialogYes } from "./PopUpElement";


export default function PopUp({children, show, onClose, onConfirm, onlyConfirm=false, textBtnNo = 'No, take me back', textBtnYes = 'OK'}) {
    
    return(
        <>
            { show ?
                <PopupOverlay>
                    <ContainerPopUp>
                        <BtnClosePopUpMsg onClick={onClose}>
                            X
                        </BtnClosePopUpMsg>
                        <ContainerChildren>
                            {children}
                        </ContainerChildren>
                        <RowSpaceBetweenOptions>
                            { !onlyConfirm ?
                                <>
                                <BtnDialogNo onClick={onClose}>
                                    <TextDialogNo>
                                        {textBtnNo}
                                    </TextDialogNo>
                                </BtnDialogNo>
                                <BtnDialogYes onClick={onConfirm}>
                                    <TextDialogYes>
                                        {textBtnYes}
                                    </TextDialogYes>
                                </BtnDialogYes>
                                </>
                                :
                                <BtnDialogYes onClick={onClose} style={{width: '100%'}}>
                                    <TextDialogYes>
                                        {textBtnYes}
                                    </TextDialogYes>
                                </BtnDialogYes>
                            }
                        </RowSpaceBetweenOptions>
                    </ContainerPopUp>
                </PopupOverlay>
                : null
            }

        </>
    )
}