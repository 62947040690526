
import styled from "styled-components";

export const ContainerGeneral = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const ContainerHeader = styled.div`
    width: auto;
    border-radius: 20px;
    background: linear-gradient(350deg, #24282f -2.06%, #16181d 102.08%);
    padding: 12px 16px 12px 16px;
    gap: 22px;
    display: flex;
`

export const ContainerLogo = styled.div`
    width: 231px;
    height: 58px;
`

export const ContainerImgLogo = styled.a`
`

export const ImgLogo = styled.img`
`

export const ContainerOptionsBar = styled.div`
    max-width: 1111px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const ContainerGeneralOptions = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
`

export const OptionBar = styled.div`
`

export const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const FirstText = styled.span`
    color: white;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 700;
    line-height: 15.73px;
`

export const SecondText = styled.span`
    color: white;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
`

export const ContainerSubMenu = styled.div`
    border-radius: 100px;
    background-color: #282C34;
    display: flex;
    gap: 24px;
`

export const OptionSubMenu = styled.button`
    border-radius: 100px;
    padding: 8px 16px 8px 16px;
    text-decoration: none;
    border: none;
    cursor: pointer;
`

export const TextOptionSubMenu = styled.span`
    color: white;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
`

export const ContainerConfigs = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
`

export const ButtonConfig1 = styled.button`
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(36, 40, 47);
    width: 32px;
    height: 32px;
    cursor: pointer;
`

export const ButtonConfig2 = styled.a`
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const ImgButton = styled.img`

`



