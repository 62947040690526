import React, { useState, useEffect, useCallback, useRef } from "react";

import { HTTP } from '../../http/http-common';
import { ArrowUpORDown, BackButton, BoxText, CheckBox, Circle, CloseButton, ContainerCellHarmonicBody, ContainerCellHarmonicHead, ContainerCellTextHarmonicBody, ContainerCellTextHarmonicHead, ContainerCodePen, ContainerColumnHarmonicBody, ContainerColumnHarmonicHead, ContainerFilters, ContainerFiltersBar, ContainerGeneral, ContainerGridOptions, ContainerHarmonics, ContainerHeaderCodePen, ContainerIconOption, ContainerOption, ContainerOptionInline, ContainerRowHarmonicBody, ContainerRowHarmonicHead, ContainerSearchSymbolInput, ContainerTableHarmonic, ContainerTableHarmonicBody, ContainerTableHarmonicHead, ContainerTitleOption, ContainerTitleOptionsV2, FilterButton, FilterButton2, GridOption, IconOption, ImgSearchSymbol, MainContainerCodePen, ModalOptions, SearchSymbolInput, SubActionsContainer, SubModalContainer, TitleGridOption, TitleOptions, TitleOptionsV2, TitleSetup } from "./NotificationsPageElement";
import { checkIfIncludedSymbol, convertTimestampToDate, futuresCommodities, futuresIndices, majorCryptoPairs, majorPairs, minorPairs, pairs, useOutsideAlerter } from "../../utils/utils";

import InputSearchIcon from '../../../assets/images/InputSearchIcon.png'
import IconTelegramPlane from '../../../assets/images/IconTelegramPlane.png'

import ArrowDown from '../../../assets/images/ArrowDown.png'
import ArrowUp from '../../../assets/images/ArrowUp.png'
import PopUp from "../../hooks/PopUp";
import { TextPoppins } from "../../../AppElement";
import { Loader } from "../../hooks/Loader";
import TradingViewWidget from "../LiveChart/TradingViewWidget";

function setRow(data, props) {
  console.log("SET ROW", data)
  if (props.setData && props.setCurrentSymbol && props.setInterval && props.setTakeProfits && props.setStopLoss && props.setEntryPrice){
    props.setData(data)
    props.setCurrentSymbol(data.symbol)
    props.setInterval(data.displaytimeframe)
    props.setTakeProfits({"profit1": data.profit1, "profit2": data.profit2, "time" : data.atime})
    props.setStopLoss(data.stoploss)
    props.setEntryPrice(data.entry)
  }
}

function HarmonicTable(props) {

  return (
    <ContainerTableHarmonic style={{overflowY: props.rows.length <= 1 ? "hidden" : "scroll"}}>
      <ContainerTableHarmonicHead>
        <ContainerRowHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Symbol
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Pattern
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Status
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                TimeFrame
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
               Entry 1
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
               Entry 2
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Profit 1
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Stoploss
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Reward/Risk
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead style={{width: '130px'}}>
              <ContainerCellTextHarmonicHead>
                Date
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
        </ContainerRowHarmonicHead>
      </ContainerTableHarmonicHead>
      <ContainerTableHarmonicBody>
        {
          props.rows.map((row) => (
            <>
            { checkIfIncludedSymbol(row.displaysymbol) ?
              <ContainerRowHarmonicBody onClick={()=>setRow(row, props)} >
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody style={{display: 'flex'}}>
                    {parseFloat(row.profit1) < parseFloat(row.entry) && parseFloat(row.stoploss) > parseFloat(row.entry) ?
                      <ArrowUpORDown src={ArrowDown} />
                      :
                      <ArrowUpORDown src={ArrowUp} />
                    }
                    <ContainerCellTextHarmonicBody>
                      {row.displaysymbol}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {row.patternname}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                    {row.status === 'failed'?
                        <Circle style={{background: '#DF0707'}} />
                      :
                        <>
                        {row.status === 'successful'?
                          <Circle style={{background: '#1DA534'}}/>
                        :
                          <>
                          {row.status === 'complete'?
                            <Circle style={{background: '#0099ff'}}/>
                          :
                            <>
                            {row.status === 'incomplete'?
                              <Circle style={{background: '#ffff00'}}/>
                            :
                              null
                            }
                            </>
                          }
                          </>
                        }
                        </>
                      }
                      {row.status}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {row.displaytimeframe}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.entry).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.entry).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.profit1).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.stoploss).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                        <>
                          {parseFloat(row.profit1) < parseFloat(row.entry) && parseFloat(row.stoploss) > parseFloat(row.entry) ?
                            <>
                              1:{Math.round((parseFloat(row.profit1) -  parseFloat(row.entry)) / (parseFloat(row.entry) - parseFloat(row.stoploss)))}
                            </>
                            :
                            <>
                              1:{Math.round((parseFloat(row.entry) - parseFloat(row.profit1)) / (parseFloat(row.stoploss) - parseFloat(row.entry)))}
                            </>
                          }
                        </>
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                    {convertTimestampToDate(row.date)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
              </ContainerRowHarmonicBody>
              :
              null
            }
          </>
          ))
        }
      </ContainerTableHarmonicBody>
    </ContainerTableHarmonic>
  );
}

function ContainerOptions(props){

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name)
    console.log(props.filters)
    if (props.filters.includes(name)){
      props.setFilters(prevState => prevState.filter(item => item !== name));
    }else{
      if (checked){
        props.setFilters(prevState => [...prevState, name]);
      }
    }
  }
  return (
    <>
      <TitleOptions>
        {props.title}
      </TitleOptions>
      <ContainerGridOptions>
        {
          props.items.map((item) => (
            <GridOption>
              <CheckBox type="checkbox" onChange={handleCheckboxChange} name={item} checked={props.filters.includes(item)}/>
              <TitleGridOption>
                {item}
              </TitleGridOption>
            </GridOption>
          ))
        }
      </ContainerGridOptions>
    </>
  );
}

// function ContainerOptionsV2(props){

//   return (
//     <ContainerOptionInline>
//       <ContainerTitleOption>
//         <ContainerIconOption>
//           <IconOption src={props.icon} />
//         </ContainerIconOption>
//         <TitleOptionsV2 style={{border: 'none', justifyContent: 'center'}}>
//         {props.title}
//         </TitleOptionsV2>
//       </ContainerTitleOption>
//       <ContainerOption onClick={()=>window.open("https://t.me/Avantage00001_bot", "_blank")}>
//         <TitleSetup>
//           Setup Now
//         </TitleSetup>
//         {/* <Switch /> */}
//       </ContainerOption>
//     </ContainerOptionInline>
//   );
// }

// function Switch(){
//   return (
//     <LabelSwitch>
//       <ActiveButton type="checkbox" />
//       <SpanSlider/>
//     </LabelSwitch>
//   )
// }


export function NotificationsPage(props) {

  const [filtersStatus, setFiltersStatus] = useState([]);
  const [filtersTimeFrame, setFiltersTimeFrame] = useState([]);
  const [filtersPattern, setFiltersPattern] = useState([]);
  const [filtersSymbol, setFiltersSymbol] = useState([]);

  const [data, setData] = useState(null);
  const [currentSymbol, setCurrentSymbol] = useState();
  const [interval, setInterval] = useState("");
  const [takeProfits, setTakeProfits] = useState([]);
  const [entryPrice, setEntryPrice] = useState(0);
  const [stopLoss, setStopLoss] = useState(0);

  const [timeFrame, setTimeFrame] = useState([]);
  const [status, setStatus] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [symbol, setSymbol] = useState([]);

  const [openSettings, setOpenSettings] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openPairs, setOpenPairs] = useState(false);

  const [showPopUp, setShowPopUp] = useState(false);

  const [loader, setLoader] = useState(false);

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const useRefopenSettings = useRef(null);
  useOutsideAlerter(useRefopenSettings, setOpenSettings);

  const useRefopenPairs = useRef(null);
  useOutsideAlerter(useRefopenPairs, setOpenPairs);

  const useRefopenNotifications = useRef(null);
  useOutsideAlerter(useRefopenNotifications, setOpenNotifications);

  const fetchGetPairs = useCallback(() => {
    setLoader(true);
    HTTP.request('get/pairs/telegram', {withCredentials: true})
      .then((res) => {
        if (res.data.result) {
          setFiltersSymbol(res.data.response)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const updatePairs = () => {
    setLoader(true);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = {
      "pairs": filtersSymbol
    };

    var config = {
      headers: headers,
      data: raw,
      method: "POST",
      withCredentials: true
    };

    HTTP.request('update/pairs/telegram', config)
    .then((res) => {
        console.log(res);
    })
    .catch((err) => {
        console.log(err);
    })
    .finally(() => {
      setLoader(false);
    });

    setOpenPairs(false)
  };

  const fetchRoutesTimes = useCallback(() => {
    setLoader(true);

    HTTP.request('scan/pattern/allpoints')
      .then((res) => {
        console.log("RESRES", res);
        if (res.data.result) {
          setRows(res.data.rows);
          setFilteredRows(res.data.rows);

          setStatus([...new Set(res.data.rows.map(item => item.status))]);
          setTimeFrame([...new Set(res.data.rows.map(item => item.displaytimeframe))]);
          setPattern([...new Set(res.data.rows.map(item => item.patternname))]);
          setSymbol(pairs);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  }, []);

  const searchSymbolInput = (event) => {
    console.log(event)
    var value = event.target.value;
    if (value.length > 0){
      setFilteredRows(rows.filter(item => 
        (value.length > 0? item.displaysymbol.includes(value.toUpperCase()) : true)
      ));
    }else{
      setFilteredRows(rows.filter(item => 
        (filtersTimeFrame.length > 0 ? filtersTimeFrame.includes(item.displaytimeframe)  : true)
        & (filtersPattern.length > 0 ? filtersPattern.includes(item.patternname)  : true) 
        & (filtersSymbol.length > 0 ? filtersSymbol.includes(item.displaysymbol)  : true)
      ));
    }
    
  }

  useEffect(() => {
    props.fetchHealth();
  }, [props]);

  useEffect(() => {
    fetchGetPairs();
    fetchRoutesTimes();
  }, [fetchRoutesTimes, fetchGetPairs]);

  useEffect(() => {
    if (filtersTimeFrame.length > 0 || filtersPattern.length > 0 || filtersStatus.length > 0 || filtersSymbol.length > 0){
      var filterResult = rows;
      var newFilterResult = []
      newFilterResult = newFilterResult.concat(filterResult.filter(item => 
        (document.getElementById("idSearchSymbol").value.length > 0? item.displaysymbol.includes(document.getElementById("idSearchSymbol").value) : true)
        & (filtersStatus.length > 0 ? filtersStatus.includes(item.status)  : true)
        & (filtersTimeFrame.length > 0 ? filtersTimeFrame.includes(item.displaytimeframe)  : true)
        & (filtersPattern.length > 0 ? filtersPattern.includes(item.patternname)  : true) 
        & (filtersSymbol.length > 0 ? filtersSymbol.includes(item.displaysymbol)  : true)
      ));

      console.log(newFilterResult)
      setFilteredRows(newFilterResult);
    }else{
      setFilteredRows(rows.filter(item => 
        (document.getElementById("idSearchSymbol").value.length > 0? item.displaysymbol.includes(document.getElementById("idSearchSymbol").value) : true)
      ));
    }
  }, [rows, filtersTimeFrame, filtersPattern, filtersStatus, filtersSymbol]);

  const clearEverything = () =>{
    setFiltersSymbol([])
    setFiltersStatus([]);
    setFiltersTimeFrame([]);
    setFiltersPattern([]);
    setShowPopUp(false);
  }
  
  return (
    <ContainerGeneral>
        <Loader loading={loader}/>
        {!data ?
        <ContainerHarmonics>
          <ContainerFilters>
            <ContainerSearchSymbolInput>
              <ImgSearchSymbol src={InputSearchIcon}/>
              <SearchSymbolInput onKeyUp={searchSymbolInput} placeholder="Search symbol" id="idSearchSymbol" />
            </ContainerSearchSymbolInput>
            <ContainerFiltersBar>
              {openSettings ?
                <FilterButton2>
                  Settings
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenSettings(true)}>
                  Settings
                </FilterButton>
              }
              {openSettings ? 
                <ModalOptions ref={useRefopenSettings}>
                  <SubModalContainer>
                    <ContainerOptions title='Status' items={status} setFilters={setFiltersStatus} filters={filtersStatus}/>
                    <ContainerOptions title='Time Frame' items={timeFrame} setFilters={setFiltersTimeFrame} filters={filtersTimeFrame}/>
                    <ContainerOptions title='Pattern' items={pattern} setFilters={setFiltersPattern} filters={filtersPattern}/>
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => setOpenSettings(false)}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              {openPairs ?
                <FilterButton2>
                  Pairs
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenPairs(true)}>
                  Pairs
                </FilterButton>
              }
              {openPairs ?
                <ModalOptions ref={useRefopenPairs}>
                  <SubModalContainer>
                    <ContainerOptions title='Major Pairs' items={symbol.filter(element => majorPairs.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Minor Pairs' items={symbol.filter(element => minorPairs.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Futures Indices' items={symbol.filter(element => futuresIndices.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Futures Commodities' items={symbol.filter(element => futuresCommodities.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Cryptocurrency Pairs' items={symbol.filter(element => {
                        if (element.includes("/")) {
                          if (majorCryptoPairs.includes(element.split('/')[0])){
                            return element
                          }
                        }
                        return null
                      })} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => updatePairs()}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              {openNotifications ?
                <FilterButton2>
                  Notifications
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenNotifications(true)}>
                  Notifications
                </FilterButton>
              }
              {openNotifications ? 
                <ModalOptions ref={useRefopenNotifications} style={{width: '396px'}}>
                  <SubModalContainer>
                    <ContainerOptionInline>
                      <ContainerTitleOption>
                        <ContainerIconOption>
                          <IconOption src={IconTelegramPlane} />
                        </ContainerIconOption>
                        <ContainerTitleOptionsV2>
                          <TitleOptionsV2 style={{border: 'none', justifyContent: 'left'}}>
                            Telegram (send the command and use your monitor telegram password)
                          </TitleOptionsV2>
                          <TitleOptionsV2 style={{border: 'none', justifyContent: 'left'}}>
                            Command: <BoxText>/monitorNotifications</BoxText>
                          </TitleOptionsV2>
                          <TitleOptionsV2 style={{border: 'none', justifyContent: 'left'}}>
                            Password: <BoxText>{props.profileData && props.profileData.telegram_pass}</BoxText>
                          </TitleOptionsV2>
                        </ContainerTitleOptionsV2>
                      </ContainerTitleOption>
                      <ContainerOption onClick={()=>window.open("https://t.me/Avantage00001_bot", "_blank")}>
                        <TitleSetup>
                          Setup Now
                        </TitleSetup>
                        {/* <Switch /> */}
                      </ContainerOption>
                  </ContainerOptionInline>
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => setOpenNotifications(false)}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              <FilterButton onClick={() => setShowPopUp(true)}>
                Clear
              </FilterButton>
            </ContainerFiltersBar>
          </ContainerFilters>
          <HarmonicTable rows={filteredRows} setData={setData} setCurrentSymbol={setCurrentSymbol} setInterval={setInterval} setTakeProfits={setTakeProfits} setStopLoss={setStopLoss} setEntryPrice={setEntryPrice}/>
        </ContainerHarmonics>
        :
        null
        }
        {data ?
          <ContainerHarmonics>
            <BackButton onClick={()=>setData(null)}>
              Back
            </BackButton>
            <ContainerHeaderCodePen>
              <HarmonicTable rows={[data]}/>
            </ContainerHeaderCodePen>
            <MainContainerCodePen>
              <ContainerCodePen>
                <TradingViewWidget data={data} symbol={currentSymbol} interval={interval} takeProfits={takeProfits} stopLoss={stopLoss} entryPrice={entryPrice}/>
              </ContainerCodePen>
            </MainContainerCodePen>
          </ContainerHarmonics>
          :
          null
        }
        <PopUp show={showPopUp} onClose={() => setShowPopUp(false)}  onlyConfirm={false} onConfirm={()=>clearEverything()}>
          <TextPoppins style={{letterSpacing: '0'}}>
          Are you sure to clear everything?
          </TextPoppins>
        </PopUp>
    </ContainerGeneral>
  );
}
