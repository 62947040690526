
import styled from "styled-components";

export const ContainerGeneral = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 100vh;
    box-sizing: border-box;
`

export const ContainerSubNavbar = styled.div`
    display: flex;
    gap: 22px;
`

export const ContainerContent = styled.div`
    width: 100%;
    flex-grow: 1;
    min-height: calc(100vh - 140px);
`

export const ContainerMain = styled.div`
    width: 100%;
    min-height: calc(100vh - 140px);
`

export const TextPoppins = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
    line-height: 35px;
    color: #FFFFFF;
`

export const TextOrbitron = styled.span`
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 13.5px;
    line-height: 45px;
    color: white;
    text-transform: uppercase;
`

export const RowCore = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;


`
export const RowCoreCenter = styled(RowCore)`
    align-items: center;
`
export const RowSpaceBetween = styled(RowCore)`
    justify-content: space-between;
    gap: 10px;
`

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`

export const ImgIcon = styled(Img)`
    margin-right: 2%;
`

export const BtnPolygon = styled.button`
    border: none;
    background-color: #E71383;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
`
