import React from "react";
import '../../index.css';
import { ButtonConfig1, ButtonConfig2, ContainerConfigs, ContainerGeneralOptions, ContainerHeader, ContainerImgLogo, ContainerLogo, ContainerOptionsBar, ContainerSubMenu, ContainerText, FirstText, ImgButton, ImgLogo, OptionBar, OptionSubMenu, SecondText, TextOptionSubMenu } from './HeaderElement';

import MainLogo from '../../assets/images/MonitorScannerMainLogo.png';

import NotificationIcon from '../../assets/images/Notification.png';
import { useLocation, useNavigate } from "react-router-dom";

  export function Header(props){
    const location = useLocation();
    console.log(location.pathname)
    const navigate = useNavigate();
    
    return (
      <ContainerHeader>
        <ContainerLogo>
          <ContainerImgLogo href="/">
            <ImgLogo src={MainLogo}/>
          </ContainerImgLogo>
        </ContainerLogo>
        <ContainerGeneralOptions>
          <ContainerOptionsBar>
            <OptionBar>
              <ContainerText>
                <FirstText>
                  Hi, {props.profileData.email && props.profileData.email.includes("@") ? props.profileData.email.split("@")[0] : props.profileData.email}
                </FirstText>
                <SecondText>
                  Welcome back
                </SecondText>
              </ContainerText>
            </OptionBar>
            <OptionBar>
              <ContainerSubMenu>
                <OptionSubMenu onClick={()=>navigate("/livechart")} 
                  style={{
                    backgroundColor: location.pathname === '/livechart' ? '#31BCC0' : 'transparent',
                    boxShadow: location.pathname === '/livechart' ? '0 10.714px 42.857px 0 rgba(49, 188, 192, 0.5)' : 'none'
                    }}>
                  <TextOptionSubMenu>
                    Live Chart
                  </TextOptionSubMenu>
                </OptionSubMenu>
                <OptionSubMenu onClick={()=>navigate("/ideas")} 
                  style={{
                    backgroundColor: location.pathname === '/ideas' ? '#31BCC0' : "transparent",
                    boxShadow: location.pathname === '/ideas' ? '0 10.714px 42.857px 0 rgba(49, 188, 192, 0.5)' : 'none'
                    }}>
                  <TextOptionSubMenu>
                    Trading Ideas
                  </TextOptionSubMenu>
                </OptionSubMenu>
                <OptionSubMenu onClick={()=>navigate("/notifications")} 
                  style={{
                    backgroundColor: location.pathname === '/notifications' ? '#31BCC0' : "transparent",
                    boxShadow: location.pathname === '/notifications' ? '0 10.714px 42.857px 0 rgba(49, 188, 192, 0.5)' : 'none'
                  }}>
                  <TextOptionSubMenu>
                    Notifications
                  </TextOptionSubMenu>
                </OptionSubMenu>
                {/* <OptionSubMenu>
                  <TextOptionSubMenu>
                    Account
                  </TextOptionSubMenu>
                </OptionSubMenu> */}
              </ContainerSubMenu>
            </OptionBar>
            <OptionBar>
              <ContainerConfigs>
                {/* <ButtonConfig1>
                  <ImgButton style={{width: '24px', height: '24px'}} src={SettingIcon}/>
                </ButtonConfig1> */}
                <ButtonConfig1 onClick={()=>navigate("/notifications")} style={{border: location.pathname === '/notifications' ? '1px solid #31BCC0' : "none", borderRadius: '8px'}}>
                  <ImgButton style={{width: '24px', height: '24px'}} src={NotificationIcon}/>
                </ButtonConfig1>
                <ButtonConfig2 onClick={()=>navigate("/account")} style={{border: location.pathname === '/account' ? '1px solid #31BCC0' : "none", borderRadius: '8px'}}>
                  <ImgButton style={{width: '32px', height: '32px', borderRadius: '8px'}} src={props.profileData.image_src}/>
                </ButtonConfig2>
              </ContainerConfigs>
            </OptionBar>
          </ContainerOptionsBar>
        </ContainerGeneralOptions>
      </ContainerHeader>
    )
}


        