import React, { useCallback, useEffect, useState } from "react";

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {ContainerGeneral } from './AppElement';

import { HTTP } from "./components/http/http-common";
import { Home } from "./Home";

import { SignIn } from './components/Pages/Login/pages/SignIn/SignIn';
// import { SignUp } from './components/Pages/Login/pages/SignUp/SignUp';
function App() {

  const [healthCheck, setHealthCheck] = useState();
  const [doReload, setDoReload] = useState(false);
  

  const fetchHealth = useCallback(() => {
    var queryParams = new URLSearchParams(window.location.search);
    var config = {
    };
    if (queryParams.get('token')){

      var formdata = new FormData();
      formdata.append("token", queryParams.get('token'));

      config = {
          data: formdata,
          method: "POST",
          withCredentials: true
      };

      setDoReload(true)
    }else{
      config = {
        method: "POST",
        withCredentials: true
      };
    }

    HTTP.request('health/check', config)
      .then((res) => {
        console.log(res);
        if (res.data.result) {
          setHealthCheck(true)
        }else{
          setHealthCheck(false)
        }
      })
      .catch((err) => {
        setHealthCheck(false)
        console.log(err);
      });

  }, []);

  useEffect(() => {
    fetchHealth();
  }, [fetchHealth]);

  return (
    <Router>
        <ContainerGeneral>
          <Routes>
          {healthCheck === false ?
            <>
              <Route path="/signin" element={<SignIn fetchHealth={fetchHealth}/>}/>
              {/* <Route path="/signup" element={<SignUp/>}/> */}
              <Route path="*" element={<Navigate replace to="/signin" />} />
            </>
            :
            <>
            {healthCheck === true ?
              <Route path="/" element={<Home fetchHealth={fetchHealth} doReload={doReload}/>}>
                <Route path="/livechart"/>
                <Route path="/ideas"/>
                <Route path="/notifications" />
                <Route path="/account"/>
                <Route path="*"/>
              </Route>
              :
              null
            }
            </>
          }
          </Routes>
        </ContainerGeneral>
    </Router>
  );
}

export default App;
